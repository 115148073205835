.field_checkbox {
    .field_checkbox_box {
        .checkbox_image_box {
            cursor:pointer;

            > div {
                display:none;

                width:12px;
                height:12px;
                border:1px solid #E1D4C1;

                -webkit-border-radius: 6px;
                -moz-border-radius: 6px;
                border-radius: 6px;

                &.yes {
                    background-color:#E1D4C1;
                }

                &.on {
                    display:block;
                }
            }
        }
    }
}

.myform2_dropzone_box {
    padding:0 20px;
    border:1px solid #e3d4c0;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    .dropzone_box {
        padding:20px 0;
        cursor: pointer;
        color: #ADAAA2;
        background: url('/img/dropzone_ico.png') right center no-repeat;
        background-size: 30px auto;
    }

    .upload_progres_bars {
        width:100%;

        > div {
            width:100%;

            &:last-child {
                padding-bottom:10px;
            }

            > div {
                display:inline-block;

                &.dz-filename {
                    width: calc(100% - 100px);
                }

                &.dz-size {
                    width: 96px;
                }

                &.dz-progress {
                    width: 100%;
                    height: 10px;
                    background-color: #e3dedb;
                    position: relative;

                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    border-radius: 3px;

                    .dz-upload {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        background-color: #e3d4c0;

                        -webkit-border-radius: 3px;
                        -moz-border-radius: 3px;
                        border-radius: 3px;
                    }
                }
            }
        }
    }

    .previewsContainer {
        .row {
            margin-left:0;
            margin-right: 0;
            padding-bottom:10px;

            &:first-child {
                padding-top:10px;
            }

            > div {
                display:inline-block;

                &.lp {
                    display:none;
                }

                &.thumb {
                    width:70px;

                    img {
                        width:50px;
                        -webkit-border-radius: 3px;
                        -moz-border-radius: 3px;
                        border-radius: 3px;
                    }
                }

                &.name {
                    width: calc(100% - 120px);
                }

                &.action {
                    width:50px;
                    text-align: right;

                    .download {
                        display:none;
                    }

                    .delete {
                        cursor: pointer;
                        position:relative;
                        top:2px;

                        &:hover {
                            opacity:0.7;
                        }
                    }
                }
            }
        }
    }
}

